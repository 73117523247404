import { Box, Image, useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { IProduct } from "../../dtos/IProduct.dto"

interface ClaimsProps {
  product?: IProduct
  claimSizeMd?: string
  claimSizeBase?: string
}

export const Claims = (props: ClaimsProps) => {
  const { product } = props

  // Mapeamento de categorias para imagens
  const categoryImageMap: { [key: number]: string[] } = {
    //LACFREE
    6: [
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_ZeroAdicaoAcucares.svg",
      "VC_Claims_OrigemNatural.svg",
    ],
    // Probiotico
    42: ["VC_Claims_Gastro.svg", "VC_Claims_OrigemNatural.svg"],
    43: ["VC_Claims_Gastro.svg", "VC_Claims_OrigemNatural.svg"],
    44: [
      "VC_Claims_Gastro.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_ZeroAdicaoAcucares.svg",
      "VC_Claims_ZeroGordurasTotais.svg",
    ],
    // Natural Whey
    60: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_OrigemNatural.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
      "VC_Claims_Colageno.svg",
    ],
    33: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_OrigemNatural.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
      "VC_Claims_ZeroGordurasTotais.svg",
    ],
    32: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_OrigemNatural.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
      "VC_Claims_ZeroGordurasTotais.svg",
    ],
    34: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_OrigemNatural.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
      "VC_Claims_ZeroGordurasTotais.svg",
    ],
    59: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_OrigemNatural.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
      "VC_Claims_ZeroGordurasTotais.svg",
    ],
    56: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_Refrigeracao.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
    ],
    31: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_Refrigeracao.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Stevia.svg",
    ],
    // Kids
    40: ["VC_Claims_OrigemNatural.svg"],
    // Essencial
    67: ["VC_Claims_OrigemNatural.svg", "VC_Claims_Leite.svg"],
    70: ["VC_Claims_ZeroLactose.svg", "VC_Claims_Leite.svg"],
    73: [
      "VC_Claims_Receitas.svg",
      "VC_Claims_Leite.svg",
      "VC_Claims_Proteinas.svg",
    ],
    74: ["VC_Claims_Receitas.svg", "VC_Claims_Leite.svg"],
    66: [
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Receitas.svg",
      "VC_Claims_Leite.svg",
    ],
    69: [
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_Proteinas.svg",
      "VC_Claims_Leite.svg",
      "VC_Claims_Receitas.svg",
    ],
    65: [
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_BoasPraticas.svg",
      "VC_Claims_Leite.svg",
    ],
    72: [
      "VC_Claims_Receitas.svg",
      "VC_Claims_BoasPraticas.svg",
      "VC_Claims_Leite.svg",
    ],
    71: [
      "VC_Claims_Receitas.svg",
      "VC_Claims_BoasPraticas.svg",
      "VC_Claims_Leite.svg",
    ],
    75: [
      "VC_Claims_Proteinas.svg",
      "VC_Claims_ZeroLactose.svg",
      "VC_Claims_SemAcucar.svg",
    ],
  }

  // Definindo tamanhos responsivos
  const claimSize = useBreakpointValue({
    base: props.claimSizeBase || "30px",
    md: props.claimSizeMd || "45px",
  })
  const claimGap = useBreakpointValue({ base: "4px", md: "7px" })

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={claimGap}
      className="group-claims"
    >
      {product?.categories.map((categoria) =>
        categoryImageMap[categoria]?.map((imageUrl, index) => (
          <Image
            key={`${categoria}-${index}`}
            src={`https://admin.verdecampo.com.br/claims-images/${imageUrl}`}
            alt={`Claim ${imageUrl}`}
            boxSize={claimSize}
            objectFit="contain"
          />
        )),
      )}
    </Box>
  )
}
